export const unifyPhone = (phone: string | null | undefined) => {
    return phone?.replace(/[^+0-9]+/g, '').replace(/^(8|7)/, '+7')
}

export const formatPhone = (phoneNumber: string) => {
    const clearPhone = phoneNumber.replace(/[^+0-9]+/g, '')
    const matches = clearPhone.match(/^(\+?7|8)(\d{3})(\d{3})(\d{2})(\d{2})$/)

    if (!matches) {
        return phoneNumber
    }

    return `+7 ${matches.slice(2, 6).join(' ')}`
}
