import { Plugin } from '@nuxt/types';
import https from 'https';


const axiosHttps: Plugin = ({ $axios }) => {
    $axios.defaults.httpsAgent = new https.Agent({
        rejectUnauthorized: process.env.NUXT_ENV === 'production',
    })
}

export default axiosHttps