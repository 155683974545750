/* eslint-disable camelcase */
import Parser from '@apidevtools/json-schema-ref-parser'
import { Ref, computed, ref, reactive, ComputedRef } from '@nuxtjs/composition-api'
import Ajv from 'ajv'
import AjvErrors from 'ajv-errors'
import addFormats from 'ajv-formats'
import { AnySchema, AnyValidateFunction, ErrorObject } from 'ajv/dist/core'
import merge from 'lodash/merge'

interface ValidationErrors {
    [ path: string ]: any
}

interface ValidationErrorsParsed {
    [ key: string ]: string[]
}

interface ValidationField {
    lastValidValue: any
    isValidated: boolean
    isValid: boolean
    isDirty: boolean
}

interface ValidationFields {
    [ path: string ]: ValidationField
}

class ValidationException extends Error {
    public payload: ValidationErrors

    constructor (errors: ValidationErrors) {
        const message = `You have errors in following fields: ${JSON.stringify(errors)}`

        super(message)

        this.name = 'Validation'
        this.stack = (new Error(message)).stack
        this.payload = errors

        Object.setPrototypeOf(this, ValidationException.prototype)
    }
}

interface AjvErrorMessage {
    [ key: string ]: any
}

interface ValidationComponentOptions {
    schema: any
    rules?: any
    ref?: string
    key?: string
    errorMessage?: AjvErrorMessage
    fields?: string[]
}

interface ValidationComponentState {
    initialized: boolean
    validation: AnyValidateFunction | undefined
    schema: any
    key: string
    ref: string
    rules?: any
    errorMessage?: AjvErrorMessage
    fields: ValidationFields
}

interface Validation {
    isValid: ComputedRef<boolean>
    errors: Ref<ValidationErrors>
    init: () => Promise<void>
    setField: (name: string, data: ValidationField) => ValidationField
    hasField: (field: string) => boolean
    getField: (field: string) => ValidationField | null
    setErrors: (field: string, errors?: string[], force?: boolean) => void
    hasErrors: (field: string) => boolean
    getErrors: (field?: string) => ComputedRef<string[]> | ValidationErrors
    clearErrors: (field?: string) => void,
    validate: (data: any) => Promise<void>
    validateField: (data: any, field: string) => Promise<void>
}

interface ValidationFunction {
    (options: ValidationComponentOptions): Validation;
}

const ValidationComponent: ValidationFunction = (options: ValidationComponentOptions): Validation => {
    const ajv = new Ajv({
        $data: true,
        strictSchema: false,
        // removeAdditional: true,
        allErrors: true
    })

    AjvErrors(ajv)
    addFormats(ajv)

    const state = reactive<ValidationComponentState>({
        initialized: false,
        validation: undefined,
        schema: options.schema,
        key: options.key ? `schema.json?${options.key}` : 'schema.json',
        ref: options.ref || '',
        rules: options.rules || {},
        errorMessage: options.errorMessage || {},
        fields: {}
    })

    const errors = ref<ValidationErrors>({})

    const isValid = computed(() => {
        if (!state.initialized) return false

        const total: string[] = []

        Object.keys(errors.value).forEach((field: string) => {
            total.concat(errors.value[field])
        })

        return total.length === 0
    })

    const setField = (name: string, data: ValidationField): ValidationField => {
        state.fields[name] = data
        errors.value = Object.assign({}, errors.value, { [name]: [] })

        return state.fields[name]
    }

    const hasField = (field: string): boolean => {
        return Object.prototype.hasOwnProperty.call(state.fields, field)
    }

    const getField = (field: string): ValidationField | null => {
        return hasField(field) ? state.fields[field] : null
    }

    const setErrors = (field: string, err: string[] = [], force = false) => {
        if (!errors.value[field] && !force) return undefined

        errors.value = Object.assign({}, errors.value, { [field]: err })
    }

    const hasErrors = (field: string): boolean => {
        return Object.prototype.hasOwnProperty.call(errors.value, field)
            ? errors.value[field].length > 0
            : false
    }

    const getErrors = (field?: string): ComputedRef<string[]> | ValidationErrors => {
        return field ? computed(() => errors.value[field] || []) : errors.value
    }

    const clearErrors = (field?: string) => {
        if (field) return setErrors(field, [])

        Object.keys(errors.value).forEach(field => setErrors(field, []))
    }

    const validate = async (data: any): Promise<void> => {
        if (!state.initialized) throw new Error('Validation is not initialized. Call .init() first.')

        try {
            await validateSchema(data)

            clearErrors()
        } catch (err) {
            Object.entries(err).forEach(([ field, value ]) => setErrors(field, value as string[], true))

            throw new ValidationException(errors.value)
        }
    }

    const validateField = async (data: any, field: string): Promise<void> => {
        if (!state.initialized) throw new Error('Validation is not initialized. Call .init() first.')

        await validateSchema(data, field)
    }

    const getSchema = (field?: string): Promise<AnyValidateFunction> => {
        return new Promise((resolve, reject) => {
            if (!state.validation) throw new Error('Validation is not initialized. Call .init() first.')
            if (!field) return resolve(state.validation)

            try {
                const properyRef = `${state.ref}/properties/${field}`
                const validation = ajv.getSchema(`${state.key}${properyRef}`)

                if (validation === undefined) {
                    throw new ValidationException({ unknown: [ "Can't compile schema." ] })
                }

                resolve(validation)
            } catch (err) {
                reject(err)
            }
        })
    }

    const parseValidationError = (
        error: ErrorObject
    ): ValidationErrorsParsed => {
        if (!error.message) return {}

        if (error.instancePath) {
            const { message = '', instancePath } = error
            const nestedArrayParamsRegExp = /\/([0-9])\//g
            const nestedArrayParamsReplace = (...args: any[]) => (`.${args[1]}.`)
            const nestedParamsRegExp = /\//g
            const property = instancePath
                .replace('/', '')
                .replaceAll(nestedArrayParamsRegExp, nestedArrayParamsReplace)
                .replaceAll(nestedParamsRegExp, '.')

            return { [property]: [ message ] }
        }

        const matches = JSON.stringify(error.params).matchAll(/"missingProperty"\s*:\s*"([^"]*)"/g)

        return Array.from(matches, match => match[1]).reduce(
            (acc, property) => Object.assign({}, acc, { [property]: [ error.message ] }),
            {} as ValidationErrorsParsed
        )
    }

    const validateSchema = (data: any, field?: string): Promise<boolean> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // "data" can be empty string, false or 0
            if (data === null || data === undefined) {
                return reject(new ValidationException({ unknown: [ '"data" is null.' ] }))
            }

            try {
                // TODO: fix single property validation
                // const validation = await this.getSchema(field)
                const validation = await getSchema()
                const valid = validation(data)

                if (!valid && validation.errors !== null && validation.errors !== undefined) {
                    const errors: ValidationErrors = validation.errors
                        .map(error => parseValidationError(error))
                        .reduce((acc, parsed) => {
                            Object.entries(parsed).forEach(([ prop, messages ]) => {
                                acc[prop] = acc[prop] ? acc[prop].concat(messages) : messages
                            })

                            return acc
                        }, {})

                    if (!field) {
                        return reject(errors)
                    }

                    if (!errors[field]) {
                        return resolve(true)
                    }

                    return reject(new ValidationException(errors))
                }

                return resolve(true)
            } catch (err) {
                const message = 'Ошибка валидации'
                const error = new ValidationException({ [field || 'unknown']: [ message ] })

                return reject(error)
            }
        })
    }

    const init = async (): Promise<void> => {
        const validation = ajv.getSchema(`${state.key}${state.ref}`)

        if (validation) {
            state.validation = validation
        } else {
            const refs = await Parser.resolve(state.schema)
            const schema = refs.get(state.ref || '#') as AnySchema
            const merged = merge(schema, state.rules, { errorMessage: state.errorMessage })

            refs.set(state.ref || '#', merged)

            const final = refs.get('#') as AnySchema

            ajv.addSchema(final, state.key)
            state.validation = ajv.getSchema(`${state.key}${state.ref}`)
        }

        if (state.validation === undefined) {
            throw new ValidationException({ unknown: [ "Can't compile schema." ] })
        }

        state.initialized = true

        return Promise.resolve()
    }

    return {
        isValid,
        errors,
        init,
        setField,
        hasField,
        getField,
        setErrors,
        hasErrors,
        getErrors,
        clearErrors,
        validate,
        validateField
    }
}

export {
    Validation,
    ValidationComponent,
    ValidationException,
    ValidationErrors
}
