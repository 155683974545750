import { Components as Admin } from '@/types/client.admin'
import moment from 'moment'
import { compare } from 'fast-json-patch'
import { SelectOption } from '@/interfaces/select-option.interface'

export interface BinaryContent {
    [key: string]: Admin.Schemas.BinaryContent
}

export const roundTo = (
    value: number,
    afterFloat: number = 2
) => parseFloat(value.toFixed(afterFloat))

export const splitNumber = (number: number, fixedSize: number = 2) => {
    const clearNumber = roundTo(number, fixedSize)
    const integer = Math.trunc(clearNumber)
    const fracts = roundTo(Math.abs(clearNumber) - Math.abs(integer), fixedSize)

    return {
        integer,
        fracts
    }
}

export interface FormatNumberOptions {
    includeFracts?: boolean
    trimFracts?: boolean
    fixedSize?: number
    floatSign?: string
    groupSeparator?: string
    groupAfter?: number
    acceptStrings?: boolean
    acceptZero?: boolean
}
export const formatNumber = (
    input: number | string | null | undefined,
    options?: FormatNumberOptions
) => {
    const {
        includeFracts = true,
        trimFracts = false,
        fixedSize = 2,
        floatSign = ',',
        groupSeparator = ' ',
        groupAfter = 3,
        acceptStrings = false,
        acceptZero = true
    } = options || {}

    if (typeof input === 'string' && !acceptStrings) {
        return null
    }

    const number = (typeof input === 'string' ? parseFloat(input) : input) || 0

    if (number === 0 && !acceptZero) {
        return null
    }

    const { integer, fracts } = splitNumber(number)
    const integerString = String(integer)
    const fractsString = String(fracts)
        .slice(2, 2 + fixedSize)
        .padEnd(fixedSize, '0')
    const groupped
        = integerString.length > groupAfter && groupSeparator
            ? integerString
                .split('')
                .reverse()
                .reduce(
                    (full, digit, count) => digit + (count % 3 === 0 ? groupSeparator : '') + full,
                    ''
                )
                .replace(RegExp(`${groupSeparator}$|^${groupSeparator}`, 'g'), '')
            : integer

    const formattedFracts
        = includeFracts && !(trimFracts && fracts === 0) ? floatSign + fractsString : ''

    return `${groupped}${formattedFracts}`
}

export const formattedUrl = (path: string) => `https://${path}`

export interface FormatBytesOptions extends FormatNumberOptions {
    entityNames?: string[]
}
export const formatBytes = (
    size: number | null | undefined = 0,
    options: FormatBytesOptions = {}
) => {
    const {
        entityNames = [ 'Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЙБ' ],
        fixedSize = 1,
        floatSign = '.'
    } = options

    if (!size) {
        size = 0
    }

    const index = size ? Math.floor(Math.log2(size) / 10) : 0
    const sizeIndex = index > entityNames.length ? entityNames.length - 1 : index
    const count = formatNumber(size / 1024 ** sizeIndex, { ...options, fixedSize, floatSign })

    return `${count} ${entityNames[sizeIndex]}`
}

export const downloadFile = (responseData: any, fileName: string) => {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([ responseData ]))
    link.setAttribute('download', fileName)
    link.click()
    link.remove()
}

export const declension = (
    number: number | null | undefined,
    zero: string,
    one: string,
    alot: string
) => {
    if (number === undefined || number === null || number - Math.floor(number) !== 0) return alot

    const prelast = Math.floor(number / 10) % 10
    const last = number % 10

    if (last === 0 || last >= 5 || prelast === 1) {
        return zero
    }

    if (last === 1) {
        return one
    }

    return alot
}

export interface DateTransformOptions<T> {
    maxYear?: number
    falseValue?: T
    format?: string
    toFormat?: string
    minYear?: number
}
export const transformDate = <T>(date: moment.MomentInput, options?: DateTransformOptions<T>) => {
    const {
        maxYear, minYear, toFormat, format = 'DD.MM.YYYY', falseValue = false
    } = options || {}

    const momentedDate = moment(date, format)
    const years = Math.abs(moment().diff(momentedDate, 'years'))

    if (!momentedDate.isValid() || (maxYear && years > maxYear) || (minYear && years < minYear)) {
        return falseValue
    }

    return momentedDate.format(toFormat || format)
}

export function copyValues<T extends object> (to: T, from: T): T {
    return Object.entries(from).reduce((result, [ key, value ]) => {
        if (!value) return to

        result[key as keyof T] = value

        return to
    }, to)
}

export interface GenerateOptionsGeneric {
    [key: string]: SelectOption['caption']
}
export const generateOptions = <T extends GenerateOptionsGeneric>(
    elements: (keyof T)[],
    elementNames: T
) => elements.map(
        element => ({
            value: element,
            caption: elementNames[element]
        })
    )

export const convertTripletToWords = (number: number): string => {
    const units = [
        '',
        'один',
        'два',
        'три',
        'четыре',
        'пять',
        'шесть',
        'семь',
        'восемь',
        'девять'
    ]
    const teens = [
        'десять',
        'одиннадцать',
        'двенадцать',
        'тринадцать',
        'четырнадцать',
        'пятнадцать',
        'шестнадцать',
        'семнадцать',
        'восемнадцать',
        'девятнадцать'
    ]
    const tens = [
        '',
        'десять',
        'двадцать',
        'тридцать',
        'сорок',
        'пятьдесят',
        'шестьдесят',
        'семьдесят',
        'восемьдесят',
        'девяносто'
    ]
    const hundreds = [
        '',
        'сто',
        'двести',
        'триста',
        'четыреста',
        'пятьсот',
        'шестьсот',
        'семьсот',
        'восемьсот',
        'девятьсот'
    ]

    let numberCopy = number
    let words = ''

    if (numberCopy > 99) {
        words += `${hundreds[Math.floor(numberCopy / 100)]} `
        numberCopy %= 100
    }

    if (numberCopy > 19) {
        words += `${tens[Math.floor(numberCopy / 10)]} `
        numberCopy %= 10
    } else if (numberCopy > 9) {
        words += teens[numberCopy - 10]
        numberCopy = 0
    }

    if (numberCopy > 0) {
        words += units[numberCopy]
    }

    return words.trim()
}

export const getPartName = (partIndex: number, number: number): string => {
    const thousands = [
        '',
        'тысяча',
        'тысячи',
        'тысяч'
    ]
    const millions = [
        '',
        'миллион',
        'миллиона',
        'миллионов'
    ]

    if (partIndex === 0) {
        return ''
    }

    if (partIndex === 1) {
        if (number % 10 === 1 && number % 100 !== 11) {
            return thousands[1]
        }

        if (number % 10 >= 2 && number % 10 <= 4
            && (number % 100 < 10 || number % 100 >= 20)
        ) {
            return thousands[2]
        }

        return thousands[3]
    }

    if (number % 10 === 1 && number % 100 !== 11) {
        return millions[1]
    }

    if (number % 10 >= 2 && number % 10 <= 4
        && (number % 100 < 10 || number % 100 >= 20)
    ) {
        return millions[2]
    }

    return millions[3]
}

export const numberToText = (number: number): string => {
    if (number === 0) return 'ноль рублей'

    let result = ''
    let partIndex = 0
    let numberCopy = number

    while (numberCopy > 0) {
        const withoutRemainder = numberCopy % 1000
        numberCopy = Math.floor(numberCopy / 1000)

        if (withoutRemainder > 0) {
            result = `${convertTripletToWords(withoutRemainder)} ${getPartName(partIndex, withoutRemainder)} ${result}`
        }

        partIndex += 1
    }

    return result.trim()
}

export const capitalize = <T extends string>(
    line: T | undefined | null
): Capitalize<T> | undefined => {
    if (!line || !line.length) return undefined

    return `${line[0].toUpperCase()}${line.slice(1)}` as Capitalize<T>
}

export const uncapitalize = <
    T extends string
>(line: T | undefined | null): Uncapitalize<T> | undefined => {
    if (!line || !line.length) return undefined

    return `${line[0].toLocaleLowerCase()}${line.slice(1)}` as Uncapitalize<T>
}

export const formatContact = (contact: string | undefined | null) => {
    if (!contact) return contact

    const matches = contact.match(/^(\+?7|8)(\d{3})(\d{3})(\d{2})(\d{2})$/)

    if (!matches) {
        return contact
    }

    return `+7 ${matches.slice(2, 6).join(' ')}`
}

export interface RoublesToTextOptions {
    trimKopeks?: boolean
    includeKopeks?: boolean
}

export const roublesToText = (number: number, options?: RoublesToTextOptions) => {
    const {
        trimKopeks = false,
        includeKopeks = true
    } = options || {}

    const { integer: roubles, fracts: kopecks } = splitNumber(number)

    const textedNumber = numberToText(roubles)
    const roubleDeclension = declension(roubles, 'рублей', 'рубль', 'рубля')

    let texted = `${textedNumber} ${roubleDeclension}`
    texted = texted[0].toUpperCase() + texted.slice(1)

    if ((kopecks > 0 && !trimKopeks) || includeKopeks) {
        const formattedKopecks = String(kopecks)
            .slice(2, 2 + 2)
            .padEnd(2, '0')
        const kopeckDeclension = declension(
            parseInt(formattedKopecks),
            'копеек',
            'копейка',
            'копейки'
        )

        texted += ` ${formattedKopecks} ${kopeckDeclension}`
    }

    return texted
}

export const nullableValue = (value: any) => {
    if (
        !value
        || (typeof value === 'string' && !value.trim())
    ) {
        return null
    }

    return value
}

export const escapeRegExp = (
    value: string
) => value.replace(/[{}[\]^$.|?*+()]/g, match => `\\${match}`)

export const excludeProperties = <T extends object, U extends keyof T>(
    object: T,
    exclude: U[]
) => (Object.keys(object) as U[]).reduce((newObject, key) => {
    if (exclude.includes(key)) {
        return newObject
    }

    const typedKey = key as unknown as Exclude<keyof T, U>

    newObject[typedKey] = object[typedKey]

    return newObject
}, {} as Omit<T, U>)

export const compareValues = (a: unknown, b: unknown) => {
    if (a === b) {
        return true
    }

    if (
        a
        && b
        && typeof a === 'object'
        && typeof b === 'object'
    ) {
        return !compare(a, b).length
    }

    return false
}

export const transliterate = (text: string) => {
    const transliterationMap: { [key: string]: string } = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'yo', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch',
        'ш': 'sh', 'щ': 'shch', 'ъ': '', 'ы': 'y', 'ь': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya',
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
        'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
        'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
        'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
        'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch',
        'Ш': 'Sh', 'Щ': 'Shch', 'Ъ': '', 'Ы': 'Y', 'Ь': '',
        'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya', ' ': '_'
    }

    return text.split('').map(
        char => (transliterationMap[char] !== undefined
            ? transliterationMap[char]
            : char)
    ).join('')
}
