/* eslint-disable camelcase */
import { OpenIDConnectScheme } from '@nuxtjs/auth-next/dist/runtime'
import type { Context } from '@nuxt/types'

export function parseQuery (queryString: string): Record<string, unknown> {
    const query = {} as any
    const pairs = queryString.split('&')
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=')
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
    return query
}

export function encodeQuery (queryObject: {
    [key: string]: string | number | boolean
}): string {
    return Object.entries(queryObject)
        .filter(([ _key, value ]) => typeof value !== 'undefined')
        .map(([ key, value ]) => encodeURIComponent(key) + (value != null ? '=' + encodeURIComponent(value) : ''))
        .join('&')
}

export function normalizePath (path = '', ctx?: Context): string {
    // Remove query string
    let [ result ] = path.split('?')

    // Remove base path
    if (ctx?.base) {
        result = result.replace(ctx.base, '/')
    }

    // Remove redundant / from the end of path
    if (result.charAt(result.length - 1) === '/') {
        result = result.slice(0, -1)
    }

    // Remove duplicate slashes
    result = result.replace(/\/+/g, '/')

    return result
}

export function getProp (
    holder: Record<string, any>,
    propName: string | false
): unknown {
    if (!propName || !holder || typeof holder !== 'object') {
        return holder
    }

    if (propName in holder) {
        return holder[propName]
    }

    const propParts = Array.isArray(propName)
        ? propName
        : (propName + '').split('.')

    let result: any = holder
    while (propParts.length && result) {
        result = result[propParts.shift()]
    }

    return result
}

export default class OIDCScheme extends OpenIDConnectScheme {
    async _handleCallback (): Promise<boolean> {
        const { $config } = this.$auth.ctx as Context
        // Handle callback only for specified route
        const secretKey = {
            AdminPortal: $config.ADMIN_PORTAL as string,
            ExecutorPortal: $config.EXECUTOR_PORTAL as string,
            PartnerPortal: $config.PARTNER_PORTAL as string
        }[this.options.clientId]

        const nullablePath = /auth\/null/.test(this.$auth.ctx.route.path)
        console.log(this.$auth.ctx.route.path, this.$auth.options.redirect.callback)
        if (nullablePath) {
            this.$auth.redirect('home', true)

            return true
        }

        if (
            this.$auth.options.redirect
            && normalizePath(this.$auth.ctx.route.path) !== normalizePath(this.$auth.options.redirect.callback)
            && this.$auth.ctx.route.path !== '/login-as-claim'
        ) {
            return false
        }
        // Callback flow is not supported in server side
        if (process.server) {
            return false
        }

        const hash = parseQuery(this.$auth.ctx.route.hash.substr(1))
        const parsedQuery = Object.assign({}, this.$auth.ctx.route.query, hash)

        // accessToken/idToken
        let token: string = parsedQuery[this.options.token.property] as string

        // refresh token
        let refreshToken: string = ''
        if (this.options.refreshToken.property) {
            refreshToken = parsedQuery[this.options.refreshToken.property] as string
        }

        // id token
        let idToken = parsedQuery[this.options.idToken.property] as string

        // Validate state
        const state = this.$auth.$storage.getUniversal(this.name + '.state')
        this.$auth.$storage.setUniversal(this.name + '.state', null)
        if (state && parsedQuery.state !== state) {
            return false
        }

        // -- Authorization Code Grant --
        if (
            (this.options.responseType === 'code' && parsedQuery.code)
            || this.$auth.ctx.route.path === '/login-as-claim'
        ) {
            let codeVerifier: any

            // Retrieve code verifier and remove it from storage
            if (
                (this.options.codeChallengeMethod
                    && this.options.codeChallengeMethod !== 'implicit')
                || this.$auth.ctx.route.path === '/login-as-claim'
            ) {
                codeVerifier = this.$auth.$storage.getUniversal(
                    this.name + '.pkce_code_verifier'
                )
                this.$auth.$storage.setUniversal(
                    this.name + '.pkce_code_verifier',
                    null
                )
            }

            const response = await this.$auth.request({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.options.endpoints.token,
                baseURL: '',
                data: encodeQuery({
                    code: parsedQuery.code as string,
                    client_id: this.options.clientId,
                    client_secret: secretKey as string || '',
                    redirect_uri: this.redirectURI,
                    response_type: this.options.responseType,
                    audience: this.options.audience,
                    grant_type: this.options.grantType,
                    code_verifier: codeVerifier
                })
            })

            token = (getProp(response.data, this.options.token.property) as string) || token
            refreshToken = (getProp(response.data, this.options.refreshToken.property) as string) || refreshToken
            idToken = (getProp(response.data, this.options.idToken.property) as string) || idToken
        }

        if (!token || !token.length) {
            return false
        }

        // Set token
        this.token.set(token)

        // Store refresh token
        if (refreshToken && refreshToken.length) {
            this.refreshToken.set(refreshToken)
        }

        if (idToken && idToken.length) {
            this.idToken.set(idToken)
        }

        if (localStorage.getItem('isLoginAsClaim') === '2') {
            localStorage.removeItem('isLoginAsClaim')

            window.location.href = localStorage.getItem('redirectLoginAsClaim') || 'https://admin.ng.workle.ru'

            return false
        }

        // Redirect to home
        this.$auth.redirect('home', true)

        return true // True means a redirect happened
    }
}
