/* eslint-disable import/no-extraneous-dependencies */
import { Plugin } from '@nuxt/types'

const authRedirect: Plugin = ({ $axios, redirect }) => {
    $axios.onError((error) => {
        if (error.response?.status === 401) {
            redirect(302, '/auth/login')

            return undefined
        }
    })
}

export default authRedirect
