// eslint-disable-next-line import/no-extraneous-dependencies
import { Middleware } from '@nuxt/types'
// eslint-disable-next-line import/extensions
import { navigationFromPlugin } from '@/plugins/link-handler'
import { ref } from '@nuxtjs/composition-api'

export const filtersInQuery = ref([])

const mergeQueryParamsMiddleware: Middleware = ({ route, from, redirect }) => {
  if (navigationFromPlugin.value) {
    navigationFromPlugin.value = false

    return undefined
  }

  const fromQuery = from.query || {}
  const toQuery = route.query || {}

  const mergedQuery = { ...fromQuery, ...toQuery }

  if (route.name?.includes('showcases-categories') && from.name?.includes('showcases-categories')) {
    filtersInQuery.value.forEach((filter: string) => {
      if (fromQuery[filter] !== undefined) {
        delete mergedQuery[filter]
      }
    })

    if (JSON.stringify(toQuery) !== JSON.stringify(mergedQuery)) {
      redirect({ path: route.path, query: mergedQuery })
    }

    return undefined
  }

  if (JSON.stringify(toQuery) !== JSON.stringify(mergedQuery)) {
    redirect({ path: route.path, query: mergedQuery })
  }
}

export default mergeQueryParamsMiddleware
