




























































































import {
    computed,
    defineComponent,
    PropType,
    ref
} from '@nuxtjs/composition-api'

import BaseModal from '@/components/ui/BaseModal.vue'

import CircledCrossIcon from '@/assets/img/close-circle.svg'

export default defineComponent({
    components: {
        CircledCrossIcon
    },
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        icon: {
            type: Object,
            default: null
        },
        confirmation: {
            type: Boolean,
            default: false
        },
        confirmationBtnText: {
            type: String as PropType<string | undefined>,
            default: 'Да'
        },
        rejectBtnText: {
            type: String as PropType<string | undefined>,
            default: 'Нет'
        },
        hideClose: {
            type: Boolean,
            default: false
        },
        wide: {
            type: Boolean,
            default: false
        },
        // eslint-disable-next-line vue/require-default-prop
        minWidth: {
            type: Number,
            required: false
        },
        bright: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const $baseModal = ref<InstanceType<typeof BaseModal> | null>(null)
        const open = () => $baseModal.value?.open()
        const close = (fromBackground: boolean = false) => $baseModal.value?.close(fromBackground)
        const isOpen = computed(() => $baseModal.value?.isOpen)
        const getMinWidth = computed(() => `minWidth: ${props.minWidth}px`)

        const confirm = () => {
            emit('on-confirm')
            close()
        }
        const reject = () => {
            emit('on-reject')
            close()
        }

        return {
            $baseModal,
            isOpen,
            getMinWidth,
            confirm,
            reject,
            open,
            close
        }
    }
})
