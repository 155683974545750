interface ResponseData<T> {
    data: T
}

type WelcomeResponseMethod = {
    welcomeRedirect?: boolean | null
}

type UpdateWelcomePayloadMethod = {
    partnerId?: string,
    welcomeRedirect?: boolean
}

export const welcomeRedirect = async (
    welcomeRedirectMethod: (partnerCompanyId: string) => Promise<
        ResponseData<WelcomeResponseMethod>
    >,
    updateWelcomeRedirectMethod: (
        queryParam: any,
        data: UpdateWelcomePayloadMethod
    ) => Promise<any>,
    partnerCompanyMethod: () => Promise<ResponseData<any>>,
    calculatorMethod: () => Promise<ResponseData<boolean>>
) => {
    const [
        { data: [ firstCompany ] },
        { data: hasPrecalculations }
    ] = await Promise.all([
        partnerCompanyMethod(),
        calculatorMethod()
    ])

    const { data: welcomeRedirectFlag } = await welcomeRedirectMethod(
        firstCompany.id
    )

    if (welcomeRedirectFlag.welcomeRedirect) {
        await updateWelcomeRedirectMethod(null, {
            partnerId: firstCompany.id,
            welcomeRedirect: false
        })

        return '/office/welcome'
    }

    if (firstCompany?.id && !hasPrecalculations) {
        return '/office/calculator'
    }

    if (firstCompany?.id) {
        return '/office'
    }
}
