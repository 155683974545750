export const dateRegExp = /^(((0[1-9]|1\d|2[0-8])\.(0[1-9]|1[0-2])|(29|30)\.(0[13-9]|1[0-2])|31\.(0[13578]|1[02]))\.(?!0000)\d{4}$)|29\.02\.(?!0000)(([02468][048]|[13579][26])00|\d{2}(0[48]|[2468][048]|[13579][26]))$/

export const phoneRegExp = /^[()\- ]*(\+?7|8)([()\- ]*[0-9][- ]*){10}[()\- ]*$/

export const emailRegExp = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+.)+[a-zA-Z]{2,}$/

export const fioRegExp = /^[A-zА-яËё]{1,}\s[A-zА-яËё]{1,}\s[A-zА-яËё]{1,}$/

export const fiRegExp = /^[A-zА-яËё]{1,}\s[A-zА-яËё]{1,}$/

export const urlRegExp = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/

export const httpLinkRegExp = /^http(s?):\/\/.*/

export const imageExtensionRegExp = /http(s?)?:\/\/.*\.(png|jpg|jpeg|gif)/

export const fileExtensionRegExp = /http(s?)?:\/\/.*\.(pdf|doc|docx|zip|rar|csv|xls|xlsx|txt)/
