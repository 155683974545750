import { computed, ref } from '@nuxtjs/composition-api'

export const asides = [ 'search', 'profile', 'catalog' ] as const
export type Aside = typeof asides[number]

const currentAside = ref<Aside | null>(null)
export const openedAside = computed(() => currentAside.value)
export const closeAside = () => {
    currentAside.value = null
}
export const openAside = (aside: Aside) => {
    currentAside.value = aside
}
