import { ref } from '@nuxtjs/composition-api'

const navigationFromPlugin = ref(false)
export { navigationFromPlugin }

export default ({ app }: { app: any }) => {
  document.addEventListener('click', (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement
    const currentUrl = new URL(window.location.href)
    const queryParameterNameToReset = targetElement.closest('svg')?.getAttribute('name')

    if (queryParameterNameToReset && currentUrl.searchParams.has(queryParameterNameToReset)) {
      currentUrl.searchParams.delete(queryParameterNameToReset)

      navigationFromPlugin.value = true

      app.router.push({
        path: currentUrl.pathname,
        query: Object.fromEntries(currentUrl.searchParams)
      })

      return undefined
    }

    const link = targetElement.closest('a')
    if (link && link.href) {
      event.preventDefault()

      const linkUrl = new URL(link.href)

      currentUrl.searchParams.forEach((value, key) => {
        if (!linkUrl.searchParams.has(key)) {
          linkUrl.searchParams.append(key, value)
        }
      })

      if (
        app.$config.BUNDLE_TYPE === 'customer'
        && (link.href.includes(app.$config.CUSTOMER_ENDPOINT)
        || link.href.includes('ng.workle.ru'))
    ) {
        app.router.push({
            path: linkUrl.pathname,
            query: Object.fromEntries(linkUrl.searchParams)
        })
    } else if (!window.location.href.includes(linkUrl.hostname)) {
        window.open(`${linkUrl.href}`)
    } else {
        app.router.push({
            path: linkUrl.pathname,
            query: Object.fromEntries(linkUrl.searchParams)
        })
    }
    }
  })
}
