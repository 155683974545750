/* eslint-disable import/no-extraneous-dependencies */
import { Middleware } from '@nuxt/types'

import { welcomeRedirect } from '@/composables/welcome-redirect'

const partnerRedirect: Middleware = async ({
    $apiPartner,
    $auth,
    redirect
}) => {
    try {
        if (!$auth.loggedIn) {
            throw new Error('User is not logged in')
        }

        const redirectUrl = await welcomeRedirect(
            $apiPartner.partnerRedirectGetWelcomeRedirect,
            $apiPartner.partnerRedirectUpdateWelcomeRedirect,
            $apiPartner.partnerMembersGetPartnersForUser,
            $apiPartner.leadCostCalculatorCheckPrecalculations
        )

        if (!redirectUrl) {
            return undefined
        }

       redirect(redirectUrl)
    } catch (error) {}
}

export default partnerRedirect
