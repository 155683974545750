/* eslint-disable no-underscore-dangle */

import Vue from 'vue'
import { Validation, ValidationException } from '@/composables/validation-component'

export interface ValidationSettings {
    validation: Validation
    transform?: (value: unknown) => unknown
    success?: () => void
}

enum ValidateFieldEvents {
    Input = 'input',
    Blur = 'blur',
    Change = 'change',
    Collapse = 'collapse'
}

interface HTMLElementVue extends HTMLElement {
    __vue__: Vue
}

const hasNestedValue = (name: string) => /\./.test(name)

const getNestedFieldValue = (name: string, value: any) => {
    const regexp = /([a-z0-9]+)\.(?=([a-z]+))/gi
    const matches = [ ...name.matchAll(regexp) ].reverse()

    return matches.reduce((acc: null | { [ key: string ]: any }, match, curr) => {
        const [ , prop, el ] = match
        const isArray = /^\d+$/.test(prop)

        if (isArray) {
            const arr = new Array(parseInt(prop, 10))

            arr.push({ [el]: curr === 0 ? value : acc })

            return arr
        }

        const data: { [ key: string ]: any } = {}

        data[el] = curr === 0 ? value : acc

        return data
    }, null)
}

const ValidateFieldDirective: Vue.DirectiveOptions = {
    bind (el, binding, vnode) {
        if (!vnode.context || vnode.componentInstance === undefined) return undefined

        const { __vue__: component } = el as HTMLElementVue
        const settings = (binding.value || {}) as ValidationSettings
        const {
            validation,
            success = () => {},
            transform = (value: any) => value
        } = settings
        let { blur = false } = binding.modifiers

        const {
            change = false,
            input = false,
            collapse = false,
            lazy = false,
            eager = false
        } = binding.modifiers

        if (!component.$props.name) {
            console.warn(`[${binding.name}]: Element must have 'name' attribute:`, el)

            return undefined
        }

        if (!validation) {
            console.warn(`[${binding.name}]: Add 'Validation' to your root component`, vnode.context.$vnode.elm)

            return undefined
        }

        if (!blur && !change && !input && !collapse) {
            blur = true
        }

        const fieldValue = validation.setField(component.$props.name, {
            lastValidValue: structuredClone(component.$props.value),
            isValidated: false,
            isValid: false,
            isDirty: false
        })

        const validateComponent = async (field: string, value: any, eagerCheck = false) => {
            if (fieldValue.isValidated || !vnode.context) return undefined

            // TODO: need deep comparison for objects and arrays
            const changed = JSON.stringify(fieldValue.lastValidValue) !== JSON.stringify(value)
            const data: any = {}

            if (hasNestedValue(field)) {
                const parentKey = field.substr(0, field.indexOf('.'))
                const parentKeyValue = getNestedFieldValue(field, transform(value))

                data[parentKey] = parentKeyValue
            } else {
                data[field] = transform(value)
            }

            // Do not mark field as dirty if it was eagerCheck
            if (!fieldValue.isDirty && changed && !eagerCheck) {
                fieldValue.isDirty = true
            }

            try {
                await validation.validateField(data, field)

                validation.clearErrors(field)

                fieldValue.isValid = true
                fieldValue.isValidated = true
                fieldValue.lastValidValue = structuredClone(data)

                success()
            } catch (error) {
                if (!(error instanceof ValidationException)) {
                    console.error(error)

                    return undefined
                }

                if (!changed && lazy) return undefined
                if (!fieldValue.isDirty && eagerCheck) return undefined

                validation.setErrors(field, error.payload[field])

                fieldValue.isValid = false
                fieldValue.isValidated = true
            }
        }

        component.$on([ 'blur', 'change' ], (event: Event) => {
            const { type } = event
            const target = event.target as HTMLInputElement
            const value = type === ValidateFieldEvents.Change && target.type === 'checkbox'
                ? target.checked
                : target.value

            if (type === ValidateFieldEvents.Blur && !blur) {
                return undefined
            }

            if (type === ValidateFieldEvents.Change && !change) {
                return undefined
            }

            validateComponent(component.$props.name, value)
        })

        component.$on('collapse', (value: any) => {
            if (!collapse) return undefined

            validateComponent(component.$props.name, value)
        })

        component.$on('input', (value: any) => {
            fieldValue.isValidated = false

            if (!input && !eager) {
                return undefined
            }

            const isEagerCheck = !input && eager

            validateComponent(component.$props.name, value, isEagerCheck)
        })
    }
}

if (!Vue.__validation__) {
    Vue.__validation__ = true

    Vue.directive('validate-field', ValidateFieldDirective)
}
