import Inputmask from 'inputmask'

export const numberMask: Inputmask.Options = {
    alias: 'decimal',
    placeholder: '',
    groupSeparator: ' ',
    showMaskOnHover: false,
    clearMaskOnLostFocus: false,
    allowMinus: true,
    rightAlign: false,
    autoUnmask: true
}

export const floatNumberMask: Inputmask.Options = {
    ...numberMask,
    digits: 2,
    importDataAttributes: false,
    onBeforePaste: (pastedValue, _opts) => pastedValue.replace(',', '.')
}

export const roublesMask: Inputmask.Options = {
    ...numberMask,
    digits: 2,
    suffix: ' ₽'
}
