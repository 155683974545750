var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-modal', _vm._g(_vm._b({
    ref: "$baseModal",
    attrs: {
      "name": _vm.name
    },
    scopedSlots: _vm._u([{
      key: "background",
      fn: function () {
        return [_c('div', {
          staticClass: "app-modal__background"
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function (contentScope) {
        return [_c('div', {
          staticClass: "app-modal__content-wrapper"
        }, [_c('div', {
          staticClass: "app-modal__content",
          style: _vm.getMinWidth
        }, [_c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [!(contentScope.unclosable || _vm.hideClose) ? _c('circled-cross-icon', {
          staticClass: "app-modal__close-icon",
          on: {
            "click": function ($event) {
              return _vm.close();
            }
          }
        }) : _vm._e()], 1), _vm._v(" "), _c('div', {
          class: {
            'app-modal__inner': true,
            'app-modal__inner--blue': _vm.bright
          }
        }, [_vm.$slots.icon ? _c('div', {
          staticClass: "app-modal__icon"
        }, [_vm._t("icon", null, null, contentScope)], 2) : _vm._e(), _vm._v(" "), _vm.title || _vm.$slots.title ? _c('div', {
          class: {
            'app-modal__title': true,
            'app-modal__title--wide': _vm.wide
          }
        }, [_vm._t("title", function () {
          return [_c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.title)
            }
          })];
        }, null, contentScope)], 2) : _vm._e(), _vm._v(" "), _vm.text || _vm.$slots.text ? _c('div', {
          class: {
            'app-modal__text': true,
            'app-modal__text--wide': _vm.wide
          }
        }, [_vm._t("title", function () {
          return [_c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.text)
            }
          })];
        }, null, contentScope)], 2) : _vm._e(), _vm._v(" "), _vm._t("default", null, null, contentScope), _vm._v(" "), _vm.confirmation ? [_c('app-button', {
          staticClass: "app-modal__button",
          attrs: {
            "wide": ""
          },
          on: {
            "on-click": _vm.confirm
          }
        }, [_vm._v("\n                            " + _vm._s(_vm.confirmationBtnText) + "\n                        ")]), _vm._v(" "), _c('app-button', {
          staticClass: "app-modal__button",
          attrs: {
            "secondary": "",
            "wide": ""
          },
          on: {
            "on-click": _vm.reject
          }
        }, [_vm._v("\n                            " + _vm._s(_vm.rejectBtnText) + "\n                        ")])] : _vm._e(), _vm._v(" "), _vm._t("otherContent")], 2)], 1)])];
      }
    }], null, true)
  }, 'base-modal', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }